import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { colors } from 'config/colors';
import { translationFlags } from 'config/translationFlags';
import { useDispatch, useSelector } from 'react-redux';
// Mudança logo
// import { ReactComponent as TerraNostraPrincipal } from 'assets/images/logos/TerraNostraPrincipal.svg';
import { ReactComponent as TerraNostraPrincipal } from 'assets/images/logos/TerraNostraPrincipalNew.svg';
import { ReactComponent as NotificationsIcon } from 'assets/images/icons/notificationsBell.svg';
import { ReactComponent as Help } from 'assets/images/icons/IconHelp.svg';
import CustomCard from 'components/common/cards/customCard';
import { updateLanguage, updateModalLanguage, updateFaqsLanguage, updateNotificationsModal, updateTermsModal, updateRentalLockerQrCode } from 'store/apps/appConfig';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

const Navbar = () => {

  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCountry, setSelectedCountry] = useState(false);
  const notifications = useSelector(state => state?.notifications?.Data);
  const languageModalOpen = useSelector(state => state.appConfig.languageModalOpen);
  const faqsModalOpen = useSelector(state => state.appConfig.faqsModalOpen);
  const notificationsModalOpen = useSelector(state => state.appConfig.notificationsModalOpen);

  const openLanguageChange = () => {
    dispatch(updateModalLanguage(!languageModalOpen))
    dispatch(updateFaqsLanguage(false));
    dispatch(updateNotificationsModal(false));
    dispatch(updateTermsModal(false));
    dispatch(updateRentalLockerQrCode(false));
  }

  const openFaqs = () => {
    dispatch(updateFaqsLanguage(!faqsModalOpen));
    dispatch(updateModalLanguage(false));
    dispatch(updateNotificationsModal(false));
    dispatch(updateTermsModal(false));
    dispatch(updateRentalLockerQrCode(false));
  }

  const goHome = () => {
    dispatch(updateFaqsLanguage(false));
    dispatch(updateModalLanguage(false));
    dispatch(updateNotificationsModal(false));
    dispatch(updateTermsModal(false));
    dispatch(updateRentalLockerQrCode(false));
    navigate('/home');
  }

  const getNotificationsNotReaded = () => {
    const filteredNotifications = notifications.filter(item => item.readed === false);
    return (filteredNotifications.length);
  }

  const openNotifications = () => {
    dispatch(updateNotificationsModal(!notificationsModalOpen));
    dispatch(updateFaqsLanguage(false));
    dispatch(updateModalLanguage(false));
    dispatch(updateTermsModal(false));
    dispatch(updateRentalLockerQrCode(false));
  }

  useEffect(() => {
    setSelectedCountry(translationFlags.find(key => key?.code === i18n.language))
    dispatch(updateLanguage(i18n.language))
  }, [i18n.language])

  useEffect(() => {
    i18n.changeLanguage('pt')
  }, []);

  useEffect(() => {
    dispatch(updateFaqsLanguage(false))
    dispatch(updateModalLanguage(false))
    dispatch(updateNotificationsModal(false))
    dispatch(updateTermsModal(false));
  }, [location?.pathname])

  useEffect(() => {
    window.history.replaceState({}, document.title)
  }, [location?.pathname])

  return (
    <Row style={{ background: colors?.white }} className="m-0 h-100 weight-300">
      <Col xs="12" className='h-fit-content'>
        <Row style={{ height: 112 }}>
          <Col xs="6">
            <Row className='h-100'>
              <TerraNostraPrincipal className='p-0 m-0 h-100' onClick={() => goHome()} />
            </Row>
          </Col>
          <Col xs="6">
            <Row className='h-100 d-flex justify-content-end'>
              <Col xs="3" className='h-100 me-3 px-3' id="modals">
                <CustomCard color="secondary" id="modals">
                  <Col xs="12" id="modals"
                    className='h-100 position-relative d-flex align-items-center justify-content-center'
                    onClick={() => openFaqs()}
                  >
                    <div className='border-radius w-100 h-100 position-absolute' id="modals" />
                    <Row id="modals">
                      <Help id="modals" style={{ height: "32px", fill: colors.font }} />
                    </Row>
                  </Col>
                </CustomCard>
              </Col>
              {location?.pathname === "/access-lockers" &&
                <Col xs="3" className='h-100 mx-3 px-3' id="modals" onClick={() => openNotifications()}>
                  <CustomCard color="secondary">
                    <div className='m-0 p-0 h-100 w-100 d-flex justify-content-center align-items-center position-relative'>
                      {(notifications?.length > 0 && getNotificationsNotReaded() > 0) && <div className='m-0 p-0 position-absolute d-flex justify-content-center align-items-center' style={{ background: "#FFBC64", borderRadius: "50%", width: 35, height: 35, top: 20, right: 30 }}>
                        {<span className='p-0' style={{ fontSize: 17, marginTop: 1 }}>{getNotificationsNotReaded()}</span>}
                      </div>}
                      <NotificationsIcon className='m-0 p-0 w-auto' style={{ height: "37.5%", stroke: colors.font, fill: colors.font }} />
                    </div>
                  </CustomCard>
                </Col>}
              <Col xs="3" className='h-100 ms-2 px-3 position-relative' id="modals">
                <CustomCard color="secondary" id="modals">
                  <Col xs="12" id="modals" className='h-100 position-relative d-flex align-items-center justify-content-center' onClick={() => openLanguageChange()}>
                    <div className='border-radius w-100 h-100 position-absolute' id="modals" />
                    <Row className='px-1 flag' id="modals">
                      <div id="modals" className='m-0 p-0 h-100' style={{ width: "75px" }}>
                        {selectedCountry?.flag}
                      </div>
                    </Row>
                  </Col>
                </CustomCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row >
  );
}

export default Navbar;
